import React from 'react';

import {
  Avatar,
  Button,
  SimpleCell,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';

import { AfterUserChooseProps } from './types';
import { PaymentLayout } from '../PaymentLayout';

export const AfterUserChoose: React.FC<AfterUserChooseProps> = ({
  onAfterUserChoose,
  delayAnimation = 0,
  friend,
  ref
}) => {
  if (!friend) {
    return null;
  }

  const invitationText = friend.isSelectedUserPurchased ? (
    'Узнайте, что страницы людей говорят об их темпераменте'
  ) : (
    'Узнайте, что страницы людей говорят об их темпераменте'
  );

  const buttonText = friend.isSelectedUserPurchased ? 'Узнать результаты' : 'Узнать результаты';

  return (
    <PaymentLayout
      delayAnimation={delayAnimation}
      ref={ref}
      needBuyingDescription={!Boolean(friend.isSelectedUserPurchased)}
      style={{ width: '100%' }}
    >
      <div style={{ margin: '0 32px', display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
        <Spacing size={40} />

        <Title level="2" weight="2" style={{ textAlign: 'center', marginBottom: '8px' }}>
                Вы выбрали пользователя:
        </Title>

        <SimpleCell
          disabled
          before={<Avatar size={48} src={friend.photoSrc} />}
          subtitle={friend.subtitle}
          style={{ marginBottom: '8px', cursor: 'default' }}
        >
          {friend.name}
        </SimpleCell>

        <Text style={{
          fontSize: 16,
          color: 'var(--light-text-secondary, #818C99)',
          textAlign: 'center',
          marginBottom: '8px'
        }}>
          {invitationText}
        </Text>

        <Spacing size={16} style={{ marginBottom: '8px' }} />

        <Button
          size="l"
          mode="primary"
          onClick={() => onAfterUserChoose(true)}
          style={{ minWidth: 275, alignSelf: 'center' }}
        >
          {buttonText}
        </Button>

        <Spacing size={30} />

      </div>
    </PaymentLayout>
  );
};
