import React from 'react';

import { Icon20ShoppingCartOutline } from '@vkontakte/icons';
import {
  Avatar,
  Button,
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  SimpleCell,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './DesktopAfterUserChoose.module.css';
import { DesktopAfterUserChooseProps } from './types';
import { IconedText } from '../../../../components/IconedText';
import { FRIEND_PREDICTION_PRICE } from '../../../../constants/Common';
import { PersonSVG } from '../../../../images/desktop/PersonSVG';

export const DesktopAfterUserChoose: React.FC<DesktopAfterUserChooseProps> = ({
  id,
  onBackClick,
  goToResult,
  friend
}) => {
  if (!friend) {
    return null;
  }

  const invitationText = friend.isSelectedUserPurchased ? (
    'Узнайте, что страницы людей говорят об их темпераменте'
  ) : (
    'Узнайте, что страницы людей говорят об их темпераменте'
  );

  const buttonText = friend.isSelectedUserPurchased ? 'Узнать результаты' : 'Узнать результаты';

  return (
    <Panel id={id} className={styles.panel}>
      <PanelHeader before={<PanelHeaderBack onClick={onBackClick}/>}>
              AI темперамент
      </PanelHeader>
      <Group className={styles.container} separator="hide">
        <AnimatePresence>
          <motion.div animate={{ scale: [1, 1.02, 1] }} transition={{ duration: 0.3 }}>
            <PersonSVG width="100%" height="auto" />
          </motion.div>
        </AnimatePresence>
        <Spacing size={40} />
        <div className={styles.informativeContent}>
          <Title level="2" className={styles.title}>
              Вы выбрали пользователя:
          </Title>
          <Spacing size={8} />
          <SimpleCell
            disabled
            before={<Avatar size={48} src={friend.photoSrc} />}
            subtitle={friend.subtitle}
            className={styles.cell}
            style={{ cursor: 'default' }}
          >
            {friend.name}
          </SimpleCell>
          <Spacing size={28} />
          <Text className={styles.invitationText}>
            {invitationText}
          </Text>
          <Spacing size={8} />
          <div className={styles.button}>
            <Button
              onClick={() => goToResult(true)}
              mode={'primary'}
              size="l"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </Group>
    </Panel>
  );
};
